<template>
  <div class="content fs-6 d-flex flex-column flex-column-fluid">
    <div class="toolbar">
      <div class="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
        <div
          class="d-flex flex-column align-items-start justify-content-center flex-wrap me-2"
        >
          <h1 class="text-dark fw-bolder my-1 fs-2">Utilisateurs</h1>
        </div>
      </div>
    </div>
    <Message :message="message" />
    <div class="post fs-6 d-flex flex-column-fluid">
      <div class="container-fluid">
        <div class="card">
          <div class="card-header border-0 pt-6">
            <div class="card-title">
              <div class="d-flex align-items-center position-relative my-1">
                <span class="svg-icon svg-icon-1 position-absolute ms-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="17.0365"
                      y="15.1223"
                      width="8.15546"
                      height="2"
                      rx="1"
                      transform="rotate(45 17.0365 15.1223)"
                      fill="black"
                    />
                    <path
                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  data-kt-user-table-filter="search"
                  class="form-control form-control-solid w-250px ps-14"
                  placeholder="Chercher un utilisateur"
                />
              </div>
            </div>
          </div>

          <!-- Loader -->
          <Loader :data="users" />

          <div v-if="users" class="card-body p-0">
            <div v-if="users.count > 0">
              <div class="card-body p-0">
                <div class="table-responsive">
                  <table
                    v-if="users.results"
                    class="table table-flush align-middle table-row-bordered table-row-solid gy-4"
                  >
                    <thead>
                      <tr class="text-start fw-bolder fs-6 text-uppercase gs-0">
                        <th class="min-w-125px ps-10">ID</th>
                        <th class="min-w-125px ps-9">Nom</th>
                        <th class="min-w-125px ps-9">Prénom</th>
                        <th class="min-w-125px ps-9">Pseudo</th>
                        <th class="min-w-125px ps-9">Email</th>
                        <th class="min-w-125px ps-9">Membre du staff</th>
                        <th class="min-w-125px ps-9">Statut</th>
                        <th class="min-w-125px pe-9">Date d'inscription</th>
                      </tr>
                    </thead>
                    <tbody class="fw-bold text-gray-700">
                      <tr v-for="user in users.results" :key="user.id">
                        <td class="w-250px min-w-175px ps-10">{{ user.id }}</td>
                        <td class="w-250px min-w-175px ps-9">
                          {{ user.last_name }}
                        </td>
                        <td class="w-250px min-w-175px ps-9">
                          {{ user.first_name }}
                        </td>
                        <td class="w-250px min-w-175px ps-9">
                          {{ user.username }}
                        </td>
                        <td class="w-250px min-w-175px ps-9">
                          {{ user.email }}
                        </td>
                        <td class="w-250px min-w-175px ps-9">
                          <div
                            v-if="user.is_staff == true"
                            class="badge badge-light-success fw-bolder"
                          >
                            Oui
                          </div>
                          <div
                            v-else
                            class="badge badge-light-danger fw-bolder"
                          >
                            Non
                          </div>
                        </td>
                        <td class="w-250px min-w-175px ps-9">
                          <div
                            v-if="user.is_active == true"
                            class="badge badge-light-success fw-bolder"
                          >
                            Actif
                          </div>
                          <div
                            v-else
                            class="badge badge-light-danger fw-bolder"
                          >
                            Désactivé
                          </div>
                        </td>
                        <td class="w-250px min-w-175px ps-9 pe-9">
                          {{ user.date_joined }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div v-else class="card-body ms-1">
              <div class="alert alert-secondary">
                <div class="d-flex flex-column">
                  <span class="mb-1 text-dark"
                    >Aucun utilisateur enregistré</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Message from "@/components/Message";
import Loader from "@/components/Loader";

export default {
  name: "Users",
  components: {
    Message,
    Loader,
  },
  computed: {
    ...mapGetters(["users"]),
  },
  methods: {
    ...mapActions(["getUsers"]),
    getAllUsers() {
      this.getUsers();
    },
  },
  mounted() {
    this.getAllUsers();
  },
};
</script>
